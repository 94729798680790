import Vue from 'vue'
import VueRouter from 'vue-router'

import App from './App.vue'
import Home from './views/Home.vue'
import Contribution from './views/Contribution.vue'
import How from './views/How.vue'
import Download from './views/Download.vue'
import About from './views/About.vue'
import Drones from './views/Drones.vue'
import Weapons from './views/Weapons.vue'
import Maps from './views/Maps.vue'

import VueAnalytics from 'vue-analytics';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueRouter)

Vue.config.productionTip = false

// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'G-L9L2YZK5V4'
});


const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: Home },
    { path: '/contribution', component: Contribution },
    
    { path: '/how', component: How },
    { path: '/download', component: Download }, 
    { path: '/about', component: About },
    { path: '/drones', component: Drones },
    { path: '/weapons', component: Weapons },
    { path: '/maps', component: Maps }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
})


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
