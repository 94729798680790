<template>
  <div id="app">
    <section>
      <div class="inner">
        <header id="header">
          <div class="logo" onClick="location.href='/home'">
            Drone Warrior
          </div>
        </header>
        <header>
          <h2>Hi!</h2>
        </header>
        <p class="text-justify">I am a solo indie developer who works in IT as a techlead. Game making has been my hobby for 3 years.  Drone Warrior is my first game development.</p>
        <p class="text-justify">20 years ago, after I graduated from university (computer science degree), I wanted a job in the game industry.  The employment was bad (work hours, salary, benefits, etc), still is, so I decide to venture into corporate IT.</p>
        <p class="text-justify">In 2018, I was playing a "pay to win" game on the bus when I got beat up by a player that decide to play to win over me.  I was so exasperated that I decide to make my own game.</p>
        <p class="text-justify">So I lean, Unity, C#, 3d modelling, sound engineering, gimp, vue.js, docker, Kubernetes and try to make something good out of it.</p>
        <p class="text-justify">I am not seeking to become rich over gamers, I only try to make this industry a better place, where winning means something other than I pay more than you.</p>
        <p class="text-justify">ChucK</p>
        <b-img :src="require('../assets/image/Back.jpg')" fluid alt="Go back" width="30em" onClick="window.history.back();"></b-img>
      </div>
    </section>
    <section>
      <div class="inner">
        <header>
          <h2>Special thanks</h2>
        </header>
        <p class="text-justify">To my sweetheart, support and love are powerful</p>
        <p class="text-justify">To the family, your encouragements keep me going</p>
        <p class="text-justify">To friends, your friendship, support, tests, suggestions for this project was amazing</p>
        <p class="text-justify">To colleagues, you are not paid enough to endure me. Your support made this possible, an answer to a simple question can save you weeks of workload.</p>
        <p class="text-justify">To Andrew Price, <b-link href="https://www.youtube.com/user/AndrewPPrice">Blender Guru</b-link>, thanks to your dedication for the 3D graphics community and <b-link href="https://www.poliigon.com/">Poliigon</b-link>. Learning <b-link href="https://www.blender.org//">Blender</b-link> with you was fantastic/magic</p>
        <p class="text-justify">To <b-link href="https://www.youtube.com/channel/UCYbK_tjZ2OrIZFBvU6CCMiA">Brackeys</b-link>, who help the community to learn <b-link href="https://unity.com/">Unity3D</b-link>. We miss you Brackeys teams</p>
        <p class="text-justify">To <b-link href="https://unity.com/">Unity3D</b-link> teams and community. I start by following multiple tutorials, curiosity became a passion with such great tools</p>
        <b-img :src="require('../assets/image/Back.jpg')" fluid alt="Go back" width="30em" onClick="window.history.back();"></b-img>
      </div>
    </section>

  </div>
</template>

<script>

export default {
  name: 'About',
  components: {
  }
}
</script>

<style>


</style>
