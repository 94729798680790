<template>
  <div id="app">
    
    <b-img :src="require('../assets/image/Back.jpg')"  fluid alt="Go back" width="30em" onClick="window.history.back();"></b-img>
  </div>
</template>

<script>

export default {
  name: 'Download',
  components: {
  }
}
</script>

<style>

</style>
