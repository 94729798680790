<template>
  <div id="app">
    <section>
      <div class="inner">
        <header id="header">
          <div class="logo" onClick="location.href='/home'">
            Drone Warrior
          </div>
        </header>

        <section id="main" >
          <div class="inner">
            <header class="major special">
              <h1>Your own drive - Drones</h1>
              <p class="text-justify">Drones are remotely controlled combat vehicles. They are composed of 2 parts. The torso, which is the upper movable part, is movable and supports the arms. The legs are the lower part, are responsible for the mobility of the drone.</p>
              <p class="text-justify">The central part of the torso contains the central processor. Its destruction leads to that of the drone. Destroying one leg cuts drone movement in half. The 2 destroyed legs leads to the destruction of the drone.</p>
              <p class="text-justify">The drone has several properties that can be improved: health point, weight, forward and backward movement speed, camera rotation.</p>
            </header>
          </div>
        </section>

        <header>
          <h2>Platoon - Hit the ground running</h2>
        </header>
        <div class="media">
          <span class="media-left"> 
            <div style="width:250px;margin-right:50px;">
              <b-carousel
                id="Platoon-carousel"
                indicators
                :interval="4000"> 
                <b-carousel-slide
                  :img-src="require('../assets/image/drones/FrontPlatoon.jpg')"
                ></b-carousel-slide>
                <b-carousel-slide
                  :img-src="require('../assets/image/drones/SidePlatoon.jpg')"
                ></b-carousel-slide>
                <b-carousel-slide
                  :img-src="require('../assets/image/drones/TopPlatoon.jpg')"
                ></b-carousel-slide>
              </b-carousel>
            </div>

          </span>
          <div class="media-body">
              <p>Platoon is a light drone armed with 2 light weapons. He is the fastest, but vulnerable. It can act as a scout or as a quick support troop.</p>
          </div>
        </div>
<br><br>
        <header>
          <h2>Big brother</h2>
        </header>
        <div class="media">
          <span class="media-left"> 
            <div style="width:250px;margin-right:50px;">
              <b-carousel
                id="Bigbrother-carousel"
                indicators
                :interval="4000"> 
                <b-carousel-slide
                  :img-src="require('../assets/image/drones/FrontBigbrother.jpg')"
                ></b-carousel-slide>
                <b-carousel-slide
                  :img-src="require('../assets/image/drones/SideBigbrother.jpg')"
                ></b-carousel-slide>
                <b-carousel-slide
                  :img-src="require('../assets/image/drones/TopBigbrother.jpg')"
                ></b-carousel-slide>
              </b-carousel>
            </div>
          </span>
          <div class="media-body">
              <p>The big brother is a light drone equipped with 2 light weapons and a medium weapon. It is a good combination between agility and strength. It acts as a cover for the avant-garde. He is mobile and has an impact weapon that can change the tide of combat.</p>
          </div>
        </div>
<br><br>
        <header>
          <h2>Artemis</h2>
        </header>
        <div class="media">
          <span class="media-left"> 
            <div style="width:250px;margin-right:50px;">
              <b-carousel
                id="Artemis-carousel"
                indicators
                :interval="4000"> 
                <b-carousel-slide
                  :img-src="require('../assets/image/drones/FrontArtemis.jpg')"
                ></b-carousel-slide>
                <b-carousel-slide
                  :img-src="require('../assets/image/drones/SideArtemis.jpg')"
                ></b-carousel-slide>
                <b-carousel-slide
                  :img-src="require('../assets/image/drones/TopArtemis.jpg')"
                ></b-carousel-slide>
              </b-carousel>
            </div>

          </span>
          <div class="media-body">
              <p>Artemis is a medium drone equipped with 2 medium weapons. He is the mainstay of combat, but is also the slowest and least mobile.</p>
          </div>
        </div>

      </div>

      
    </section>
    
    <b-img :src="require('../assets/image/Back.jpg')"  fluid alt="Go back" width="30em" onClick="window.history.back();"></b-img>
  </div>
</template>

<script>

export default {
  name: 'Download',
  components: {
  }
}
</script>

<style>

</style>
