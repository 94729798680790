<template>
  <div id="app">
   <router-view/>
  </div>
</template>

<script>
import '../src/assets/css/main.css';

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Lato, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  background-color: black;
}
body {
  background-color: black !important; 
}
</style>
