<template>
  <div id="app">
    <section>
      <div class="inner">
        <header id="header">
          <div class="logo" onClick="location.href='/home'">
            Drone Warrior
          </div>
        </header>        
        <header>
          <h1>Drone Warrior needs you!</h1>
        </header>
        <p class="text-justify">Players choose the development direction of the game, by playing, voting, contributing and collaborating</p>
        <p class="text-justify">I am working on this part, I will make a Kickstarter soon.</p>
          
        <p class="text-justify">
          <b-form inline action="https://yahoo.us7.list-manage.com/subscribe/post?u=85b2f33c1dc979b3aa6918ee7&amp;id=6d83d76fc6" method="post">
              <b-form-input
                id="email"
                v-model="email"
                type="email"
                placeholder="Enter email"
                required></b-form-input>
              <b-button type="submit" variant="primary">Subscribe</b-button>
          </b-form>
        </p>

        <b-img :src="require('../assets/image/Back.jpg')"  fluid alt="Go back" width="30em" onClick="window.history.back();"></b-img>
      </div>
    </section>


  </div>


</template>

<script>

export default {
  name: 'Contribution',
  components: {
  },
    data() {
      return {
          email: '',
      }
    },
}
</script>

<style>

</style>
