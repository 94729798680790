<template>
  <div id="app">
     <section>
      <div class="inner">
        <header id="header">
          <div class="logo" onClick="location.href='/home'">
            Drone Warrior
          </div>
        </header>
        <section id="main" >
          <div class="inner">
            <header class="major special">
              <h1>Where you drive - Maps</h1>
              <p class="text-justify">Maps are arenas where teams compete against each other. Adapting your strategy to the terrain is essential to get a victory. </p>
              <p class="text-justify">Small terrains favour slow but strong drones, weapons that range quickly and recharge quickly. </p>
              <p class="text-justify">Large terrains favour fast drones and long-range weapons.</p>
            </header>
          </div>
        </section>

        <header>
          <h2>Hockey night</h2>
        </header>
        <p>
          <b-img :src="require('../assets/image/maps/HockeyNight.jpg')" fluid ></b-img>
        </p>
        <div class="media">
          <span class="media-left"> 
            <div style="width:250px;margin-right:50px;">
              <img :src="require('../assets/image/maps/TopHockeyNight.jpg')" width="100%" />
            </div>
          </span>
          <div class="media-body">
              <p>This symmetrical map of 500 square meters is the smallest. Players must contend in an aggressive environment where turnovers are common. This ice floor arena is a nod to a hockey arena.</p>
          </div>
        </div>
    <br><br>


        <header>
          <h2>Fort</h2>
        </header>
        <p>
          <b-img :src="require('../assets/image/maps/Fort.jpg')" fluid ></b-img>
        </p>
        <div class="media">
          <span class="media-left"> 
            <div style="width:250px;margin-right:50px;">
              <img :src="require('../assets/image/maps/TopFort.jpg')" width="100%" />
            </div>
          </span>
          <div class="media-body">
              <p>This land is asymmetrical of 1 square km. The fort is easy to defend, but staying in the fort is not enough to win the game. The battlefield is larger and more difficult to defend, but it is here that victory can be established.</p>
          </div>
        </div>
    <br><br>


    <header>
          <h2>King of the mountain</h2>
        </header>
        <p>
          <b-img :src="require('../assets/image/maps/KingMountain.jpg')" fluid ></b-img>
        </p>
        <div class="media">
          <span class="media-left"> 
            <div style="width:250px;margin-right:50px;">
              <img :src="require('../assets/image/maps/TopKingMountain.jpg')" width="100%" />
            </div>
          </span>
          <div class="media-body">
              <p>This symmetrical land is the largest (4 square km) and has a mountain 500 meters high. The ground being very vast, the attacks from a distance are favoured. It is possible to take cover, but difficult to hide from the king of the mountain. So the team that takes the top of the mountain has a good chance of winning the game.</p>
          </div>
        </div>
    <br><br>


      </div>
     </section>
    
    <b-img :src="require('../assets/image/Back.jpg')"  fluid alt="Go back" width="30em" onClick="window.history.back();"></b-img>
  </div>
</template>

<script>

export default {
  name: 'Download',
  components: {
  }
}
</script>

<style>
.image.fit {
    display: block;
    margin: 0 0 2em 0;
    width: 100%;
}
</style>
