<template>
  <div id="app">
    <section>
      <div class="inner">
        <header id="header">
          <div class="logo" onClick="location.href='/home'">
            Drone Warrior
          </div>
        </header>    
        <section id="main" >
          <div class="inner">
            <header class="major special">
              <h1>Weapons</h1>
              <p class="text-justify">Weapons can destroy your enemies in several ways. Currently, there are 2 types of weapons: light and medium. Weapons have several properties that can be improved: remove life points from your opponent, heal your allies, firing speed, reload speed, range, radius, ammunition. They also have the ability to influence the abilities of your allies and opponents (forward and backward movement speed, camera rotation range). For example, a weapon can damage an enemy and slow them down at the same time. Weapons can also influence the ability of other weapons: fire rate, reload speed, range, radius, ammunition.</p>
            </header>
          </div>
        </section>

        <header>
          <h2>Executioner - Are you talking to me</h2>
        </header>
        <div class="media">
          <span class="media-left"> 
            <div style="width:250px;margin-right:50px;">
              <img :src="require('../assets/image/weapons/Executioner.jpg')" width="100%" />
            </div>
          </span>
          <div class="media-body">
              <p>Executioner is a light weapon. It is a 30mm calibre machine gun made to generate continuous damage at long range.</p>
          </div>
        </div>
    <br><br>


        <header>
          <h2>Uppercut</h2>
        </header>
        <div class="media">
          <span class="media-left"> 
            <div style="width:250px;margin-right:50px;">
              <img :src="require('../assets/image/weapons/Uppercut.jpg')" width="100%" />
            </div>
          </span>
          <div class="media-body">
              <p>Uppercut is a light weapon. It is a short-range missile launcher. This weapon, at short range, gives a punch to quickly eliminate an opponent, but it this empty quickly.</p>
          </div>
        </div>
    <br><br>


        <header>
          <h2>Fury</h2>
        </header>
        <div class="media">
          <span class="media-left"> 
            <div style="width:250px;margin-right:50px;">
              <img :src="require('../assets/image/weapons/Fury.jpg')" width="100%" />
            </div>
          </span>
          <div class="media-body">
              <p>Fury is a medium weapon. It's a cannon that uses one projectile at a time. It is designed to take out an opponent with a bam. It must reload after each hit. She was inspired by the movie Fury.</p>
          </div>
        </div>
    <br><br>


        <header>
          <h2>Hammer</h2>
        </header>
        <div class="media">
          <span class="media-left"> 
            <div style="width:250px;margin-right:50px;">
              <img :src="require('../assets/image/weapons/Hammer.jpg')" width="100%" />
            </div>
          </span>
          <div class="media-body">
              <p>Hammer is a medium weapon. This long-range weapon is devastating, but it's difficult to aim at your opponent. The radius compensates for its lack of precision. A nuclear mushroom erupted from the impact of the hammer.</p>
          </div>
        </div>
    <br><br>


        <header>
          <h2>BlackOut</h2>
        </header>
        <div class="media">
          <span class="media-left"> 
            <div style="width:250px;margin-right:50px;">
              <img :src="require('../assets/image/weapons/Blackout.jpg')" width="100%" />
            </div>
          </span>
          <div class="media-body">
              <p>BlackOut is a medium weapon. It is a long-range missile launcher. It deters the enemy from advancing, which fixes the problem before it becomes one.</p>
          </div>
        </div>
    <br><br>


        <b-img :src="require('../assets/image/Back.jpg')"  fluid alt="Go back" width="30em" onClick="window.history.back();"></b-img>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'Download',
  components: {
  }
}
</script>

<style>

</style>
