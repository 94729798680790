<template>
  <div id="app">
    <div class="card text-white">
      <div class="position-relative">

        <b-img 
          :srcset="
          require('../assets/image/FrontKingMoutainBackground2/FrontKingMountainBackground2_w_200.jpg') +' 200w, ' +
          require('../assets/image/FrontKingMoutainBackground2/FrontKingMountainBackground2_w_575.jpg') +' 575w, ' +
          require('../assets/image/FrontKingMoutainBackground2/FrontKingMountainBackground2_w_835.jpg') +' 835w, ' +
          require('../assets/image/FrontKingMoutainBackground2/FrontKingMountainBackground2_w_1056.jpg') +' 1056w, ' +
          require('../assets/image/FrontKingMoutainBackground2/FrontKingMountainBackground2_w_1297.jpg') +' 1297w, ' +
          require('../assets/image/FrontKingMoutainBackground2/FrontKingMountainBackground2_w_1400.jpg') +' 1400w, ' +
          require('../assets/image/FrontKingMoutainBackground2/FrontKingMountainBackground2.jpg') +' 1600w '"
          :src="require('../assets/image/FrontKingMoutainBackground2/FrontKingMountainBackground2.jpg')"
          fluid alt="Image principale" ></b-img>
        <div class="card-img-overlay">
          <header id="header">
            <div class="logo">
              Drone Warrior
            </div>
          </header>
          <br><br><br><br><br><br>
            <h1 class="card-title" style="color:white">For gamers</h1>
            <h4 class="card-text" style="color:white">Free to play & No pay to win</h4>                
        </div>
      </div>
    </div>
    <section>
      <div class="inner">
        <header>
          <h2>What is Drone Warrior ?</h2>
        </header>
        <p class="text-justify">You and your team pilot multiple futuristic combat drones/robots in an arena against an opposing team.</p>
        <p class="text-justify">The goal of Drone Warrior is to go against the grain of the video game industry. No more player microtransaction, DLC or "free to play" which are "free to lose" or watching ads 50% of the game.</p>
        <p class="text-justify">Players choose the development direction of the game, by playing, voting, <router-link to="/contribution" >contributing</router-link> and collaborating</p>
        <p class="text-justify">
            <a target="_blank" href="https://www.fnpmrun serveacebook.com/sharer/sharer.php?u=http%3A%2F%2Fdronewarrior.games%2F&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">Share</a>
        </p>
        <p class="text-justify"><router-link to="/drones">See the drones</router-link></p>
        <p class="text-justify"><router-link to="/weapons">Continue with the weapons</router-link></p>
        <p class="text-justify"><router-link to="/maps">Finaly the maps</router-link></p>
      </div>
    </section>
    <section>
      <div class="inner">
        <header>
          <h2>What new ?</h2>
        </header>
        <p class="text-justify">2021-07: MVP (Minimum Viable Product (aka demo)) Complet. Drone Warrior is currently in beta, which means it working but more testing is required to deliver better quality.</p>
        <p class="text-justify">
          If you want to stay in touch :
          <b-form inline action="https://yahoo.us7.list-manage.com/subscribe/post?u=85b2f33c1dc979b3aa6918ee7&amp;id=6d83d76fc6" method="post">
              <b-form-input
                id="email"
                v-model="email"
                type="email"
                placeholder="Enter email"
                required></b-form-input>
              <b-button type="submit" variant="primary">Subscribe</b-button>
          </b-form>
        </p>
      </div>
    </section>
    <section>
      <div class="inner">
        <header>
          <h2>A little bit about the history of the game</h2>
        </header>
        <p class="text-justify">In AD 3350, the sport became militarized. Fans complain about the growing number of injuries, the athletes are now remotely controlled robots called Drones. They have very advanced weaponry, based on nanotechnology (nanobots) which offers a variety of game strategy possibilities.</p>
        <p class="text-justify">The objective of this sport is to outgrow opponents by setting up a collective assault strategy between drone pilots, also called officers.</p>
      </div>
    </section>
    <section>
      <div class="inner">
        <header>
          <h2>How to play ?</h2>
        </header>
        <p class="text-justify">Drone Warrior is currently in beta/demo, which means it working but more testing is required to deliver better quality.</p>
        <p class="text-justify">Mobile development is on the way</p>
        <p class="text-justify">If you want to try the demo, subscribe to the newsletters.</p>
      </div>
    </section>
    <section>
      <div class="inner">
        <header>
          <h2>Why I made Drone Warrior ?</h2>
        </header>
        <p class="text-justify">Three years ago, I am on the bus going home after work, I was playing a mech game on my phone. I had been playing it for 1-2 years and the company had slowly started to transform their platform towards microtransactions and "pay to win". I remember getting kill by an opponent who paid $ 80 for a superior mech. I was so angry that I immediately uninstalled that game.</p>
        <p class="text-justify">I thought to myself that it was surely viable to have an interesting game without microtransaction and DLC. Today, 70% of these studios' revenue comes from just 10% of gamers. I find this business model abusive and demotivating.</p>
        <p class="text-justify"><router-link to="about" >About me</router-link></p>
      </div>
    </section>
    <section>
      <div class="inner">
        <header>
          <h2>What next ?</h2>
        </header>
        <p class="text-justify">2021-07: Marketing phase, newsletter, Kickstarter (and financement), Youtube channel, Patreon, Facebook, Instagram, Imgur, Reddit</p>
        <p class="text-justify">Players choose the development direction of the game, by playing, voting, <router-link to="/contribution" >contributing</router-link> and collaborating</p>
      </div>
    </section>
    <section>
      <div class="inner">
        <header>
          <h2>What about money ?</h2>
        </header>
        <p class="text-justify">Drone Warrior need gamers who <router-link to="/contribution" >contributing</router-link> and collaborating. To make this happen, we will use crowdfunding.</p>
        <p class="text-justify">Since this game is a PVP (Player against Player), crowdfunding makes sense.</p>
        <p class="text-justify">This project is an experimentation we hope to sustain for the next decade.</p>
      </div>
    </section>

  </div>
</template>

<script>


export default {
  name: 'Home',
  components: {
  },
    data() {
      return {
          email: '',
      }
    },
}
</script>

<style>

</style>
